import { Box, SvgIcon } from "@mui/material"
import { styled } from "@mui/material/styles"

import COLORS from "./colors"
import { ThemeProvider } from "./theme"
import BaseButton from "../components/Button"

export const IoxioLogo = styled(SvgIcon)`
  display: flex;
  align-self: center;
  width: 10rem;
  height: auto;
  max-height: 30px;
  margin-left: 0;
`
export const onMobile = (props) => props.theme.breakpoints.down("sm")

export const onTablet = (props) => props.theme.breakpoints.down("md")

export const onDesktop = (props) => props.theme.breakpoints.down("xl")

export const onDesktopAndAbove = (props) => props.theme.breakpoints.up("xl")

export const THEMES = {
  lightblue: {
    "--background-color": COLORS.ICE,
    "--text-color": COLORS.NIGHT_SKY,
    "--topic-title-color": COLORS.NIGHT_SKY,
  },
  dark: {
    "--background-color": COLORS.GRANITE,
    "--text-color": COLORS.SAND,
    "--topic-title-color": COLORS.SAND,
  },
  black: {
    "--background-color": COLORS.NIGHT_SKY,
    "--text-color": COLORS.SAND,
    "--topic-title-color": COLORS.NEON,
  },
  darkpurple: {
    "--background-color": COLORS.SEA,
    "--text-color": COLORS.SAND,
    "--topic-title-color": COLORS.NEON,
  },
  sand: {
    "--background-color": COLORS.SAND,
    "--text-color": COLORS.NIGHT_SKY,
    "--topic-title-color": COLORS.NIGHT_SKY,
  },
  white: {
    "--background-color": COLORS.WHITE,
    "--text-color": COLORS.NIGHT_SKY,
    "--topic-title-color": COLORS.NIGHT_SKY,
  },
}

export const HEADER_STYLES = {
  1: {
    "--header-font-size": `${65 / 16}rem`,
    "--header-font-size-mobile": `${50 / 16}rem`,
    "--header-font-weight": 600,
    "--header-line-height": "79px",
  },
  2: {
    "--header-font-size": `${51 / 16}rem`,
    "--header-font-size-mobile": `${35 / 16}rem`,
    "--header-font-weight": 700,
    "--header-line-height": "64px",
    "--header-line-height-mobile": "40px",
  },
  3: {
    "--header-font-size": `${35 / 16}rem`,
    "--header-font-size-mobile": `${30 / 16}rem`,
    "--header-font-weight": 700,
    "--header-line-height": "40px",
    "--header-line-height-mobile": "40px",
  },
}

export const StyledLink = styled("a")`
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:focus:focus-visible {
    border: 2px solid black;
    outline: none;
    box-shadow: 0 0 6px black;
  }
`
export const StyledMobileLink = styled("a")`
  width: 100%;
  color: #ffffff;
  text-decoration: none;
  background-color: ${COLORS.NEON};
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:focus:focus-visible {
    border: 2px solid black;
    outline: none;
    box-shadow: 0 0 6px black;
  }
`

export const SliceZoneWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const MAX_CONTAINER_WIDTH = "120rem"

export function getDefaultStyles(slice, index, slices) {
  const isLastSlice = index === slices.length - 1
  return {
    ...THEMES[slice.primary.theme],
    ...HEADER_STYLES[slice.primary.headerSize || 3],
    "--content-direction": slice.primary.imageOnLeftSide ? "row-reverse" : "row",
    "--wrapper-padding": index === 0 ? "9rem 0 4rem 0" : "4.5rem 0",
    "--mobile-wrapper-padding": index === 0 ? "7rem 0 3rem 0" : "4rem 0",
    "--height": isLastSlice ? "100%" : "auto",
  }
}

export const Header = styled("h1")`
  font-weight: var(--header-font-weight);
  font-size: var(--header-font-size);
  font-style: normal;
  line-height: var(--header-line-height);
  letter-spacing: 0.01rem;
  ${onMobile} {
    font-size: var(--header-font-size-mobile);
    line-height: var(--header-line-height-mobile);
  }
`

export const SubHeader = styled("h2")`
  font-weight: 700;
  font-size: calc(22 / 16 * 1rem);
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0.02rem;
  font-feature-settings: "tnum" on, "lnum" on;
`

export const ButtonsBox = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 3rem;

  ${onMobile} {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    font-size: 0.75rem;
  }
`

export const WhiteButton = styled(BaseButton)`
  --button-text-color: ${COLORS.NIGHT_SKY};
  --button-background-color: ${COLORS.SAND};
`
export const DarkButton = styled(BaseButton)`
  --button-text-color: ${COLORS.SAND};
  --button-background-color: ${COLORS.NIGHT_SKY};
`

export function Button({ item }) {
  let Component = WhiteButton
  switch (item.buttonTheme) {
    case "white":
      Component = WhiteButton
      break
    case "dark":
      Component = DarkButton
      break
  }
  return (
    <Component href={item.buttonLink.url} variant="contained">
      {item.buttonText}
    </Component>
  )
}

export { COLORS, ThemeProvider }
