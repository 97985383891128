import { NextSeo } from "next-seo"

import conf from "../config.json"

export default function PrismicSEO({ metaImage, metaTitle, metaDescription }) {
  const ogImages = []
  if (metaImage && metaImage.url) {
    ogImages.push({
      url: metaImage.url,
      width: metaImage.dimensions.width,
      height: metaImage.dimensions.height,
      alt: metaImage.alt,
    })
  }

  return (
    <NextSeo
      title={metaTitle}
      description={metaDescription}
      canonical={conf.baseUrl}
      openGraph={{
        type: "website",
        url: conf.baseUrl,
        images: ogImages,
        locale: "en",
      }}
      twitter={{
        cardType: "summary",
      }}
    />
  )
}
