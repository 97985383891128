import * as prismic from "@prismicio/client"
import { enableAutoPreviews } from "@prismicio/next"
import Link from "next/link"

import smConfig from "./sm.json"
import { StyledLink } from "./styles"

if (!smConfig.apiEndpoint) {
  console.warn("Looks like Slice Machine hasn't been bootstraped already")
}

export const apiEndpoint = smConfig.apiEndpoint
export const repositoryName = smConfig.repoName

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = process.env.PRISMIC_TOKEN

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  switch (doc.type) {
    case "homepage":
      return `/`
    case "page":
      return `/${doc.uid}`
    case "developer-guide":
      return `/guides/${doc.uid}`
    case "press-release":
      return `/press-releases/${doc.uid}`
    default:
      return null
  }
}

export const CustomLink = (type, element, content, children, index) => {
  return (
    <Link key={index} href={element.data.url} as={element.data.url} passHref>
      <StyledLink tabIndex={0} target="_blank">
        {content}
      </StyledLink>
    </Link>
  )
}

export const Router = {
  routes: [
    { type: "case-study", path: "/cases/:uid" },
    { type: "developer-guide", path: "/guides/:uid" },
    { type: "page", path: "/:uid" },
    { type: "press-release", path: "/press-releases/:uid" },
  ],
  href: (type) => {
    const route = Router.routes.find((r) => r.type === type)
    return route && route.href
  },
}
export function createClient(config = {}) {
  const client = prismic.createClient(
    apiEndpoint,
    // createClientOptions(config, accessToken, Router)
    createClientOptions(config, accessToken)
  )

  enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  })

  return client
}

// Options to be passed to the Client
const createClientOptions = (
  config = null,
  prismicAccessToken = null,
  routes = null
) => {
  const reqOption = config ? { config } : {}
  const accessTokenOption = prismicAccessToken
    ? { accessToken: prismicAccessToken }
    : {}
  const routesOption = routes ? { routes: Router.routes } : {}
  return {
    ...reqOption,
    ...accessTokenOption,
    ...routesOption,
  }
}
