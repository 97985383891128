import React from "react"

import { CssBaseline } from "@mui/material"
import { PrismicPreview } from "@prismicio/next"
import { PrismicProvider } from "@prismicio/react"
import Link from "next/link"

import Layout from "../components/Layout"
import PrismicSEO from "../components/PrismicSEO"
import { linkResolver, repositoryName } from "../prismic-configuration"
import { ThemeProvider } from "../styles"
import GlobalStyles from "../styles/globalStyles"

import "@fontsource/poppins/100.css"
import "@fontsource/poppins/200.css"
import "@fontsource/poppins/300.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import "@fontsource/poppins/800.css"
import "@fontsource/poppins/900.css"
import "@fontsource/poppins/100-italic.css"
import "@fontsource/poppins/200-italic.css"
import "@fontsource/poppins/300-italic.css"
import "@fontsource/poppins/400-italic.css"
import "@fontsource/poppins/500-italic.css"
import "@fontsource/poppins/600-italic.css"
import "@fontsource/poppins/700-italic.css"
import "@fontsource/poppins/800-italic.css"
import "@fontsource/poppins/900-italic.css"

function MyApp({ Component, pageProps }) {
  const isSliceSimulatorPage = Component.name === "SliceSimulatorPage"
  return (
    <ThemeProvider>
      <CssBaseline />
      <GlobalStyles />
      <PrismicProvider
        linkResolver={linkResolver}
        internalLinkComponent={({ href, children, ...props }) => (
          <Link href={href}>
            <a {...props}>{children}</a>
          </Link>
        )}
      >
        <PrismicPreview repositoryName={repositoryName}>
          {pageProps.data && <PrismicSEO {...pageProps.data} />}

          {isSliceSimulatorPage ? (
            <Component {...pageProps} />
          ) : (
            <Layout>
              <Component {...pageProps} />
            </Layout>
          )}
        </PrismicPreview>
      </PrismicProvider>
    </ThemeProvider>
  )
}

export default MyApp
