import React from "react"

import { Box, Grid, styled } from "@mui/material"

import Footer from "./Footer"
import Navbar from "./Navbar"
import prismicCustomTypes from "../menuItems.json"

export default function Layout({ children }) {
  return (
    <WrapperBox>
      <ContainerGrid container rowSpacing={3}>
        <Navbar menu={prismicCustomTypes.menu[0]} />
        <Grid item xs={12}>
          {children}
        </Grid>
      </ContainerGrid>
      <FooterBox>
        <Footer footer={prismicCustomTypes.footer} />
      </FooterBox>
    </WrapperBox>
  )
}

export const WrapperBox = styled("main")`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ContainerGrid = styled(Grid)`
  flex: 1;
`

export const FooterBox = styled(Box)`
  flex-shrink: 0;
  margin-top: auto;
`
