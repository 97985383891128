import React from "react"

import YouTubeIcon from "@mui/icons-material/YouTube"
import { Box, Grid, styled, Typography } from "@mui/material"
import Link from "next/link"

import MUIRichText from "./MuiRichText"
import IOXIOSvg from "../public/assets/ioxioLogo.svg"
import LinkedInLogo from "../public/assets/linkedin.svg"
import SlackLogo from "../public/assets/slack.svg"
import { COLORS, MAX_CONTAINER_WIDTH, onMobile } from "../styles"

const year = new Date().getFullYear()

export default function Footer({ footer }) {
  const data = footer[0].data
  return (
    <FooterContainer>
      <MainFooter>
        <FooterGridContainer container>
          <Grid item xs={0} sm={2}>
            <Link href="/">
              <a>
                <IOXIOLogo />
              </a>
            </Link>
          </Grid>
          <Grid item xs={4.5} sm={4}>
            <Contacts>
              {data?.contacts.map((contact, index) => (
                <Contact
                  key={index}
                  info={contact.info}
                  phone={contact.phone}
                  type={contact.type}
                  email={contact.email}
                >
                  <MUIRichText field={contact.additionalContacts} />
                </Contact>
              ))}
            </Contacts>
          </Grid>
          <Grid item xs={4.5} sm={4}>
            <Contacts>
              <AddressBox>
                <Title>{data.company}</Title>
                <SubTitle>{data.address}</SubTitle>
                <SubTitle>
                  {data.zipCode} {data.city}
                </SubTitle>
                <SubTitle>{data.country}</SubTitle>
              </AddressBox>
              <SocialLinks>
                <Anchor
                  href="https://youtube.com/channel/UCJZ-ddGu8W-YITcWce5NVBQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YouTubeIcon fontSize="large" />
                </Anchor>
                <Anchor
                  href="https://linkedin.com/company/ioxio-company/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInLogo />
                </Anchor>
                <Anchor
                  href="https://slack.ioxio.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SlackLogo />
                </Anchor>
              </SocialLinks>
            </Contacts>
          </Grid>
        </FooterGridContainer>
      </MainFooter>
      <BottomFooter>
        <BottomFooterGridContainer container>
          <Grid item xs={0} sm={2} />
          <BottomFooterGridItem item xs={9} sm={4} md={6}>
            IOXIO® is a registered trademark of IOXIO Oy. IOXIO Dataspace™ is a
            trademark of IOXIO Oy.
          </BottomFooterGridItem>
          <BottomFooterGridItem item xs={9} sm={4} md={2}>
            Copyright © IOXIO® {year}.
          </BottomFooterGridItem>
        </BottomFooterGridContainer>
      </BottomFooter>
    </FooterContainer>
  )
}

const Contact = ({ type, email, phone, info, children }) => (
  <ContactWrapper>
    <Title>{type}</Title>
    {email && (
      <SubTitle>
        <Anchor href={`mailto:${email}`}>{email}</Anchor>
      </SubTitle>
    )}
    {phone && (
      <SubTitle>
        <Anchor href={`tel:${phone}`}>{email}</Anchor>
      </SubTitle>
    )}
    {info && <SubTitle>{info}</SubTitle>}
    <SubTitle>{children}</SubTitle>
  </ContactWrapper>
)

export const Contacts = styled(Box)`
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  width: 100%;
  min-width: 0;
  margin-top: 0;
  ${(props) => props.theme.breakpoints.down("md")} {
    grid-template-columns: auto;
  }
`

const Anchor = styled("a")`
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
`

const AddressBox = styled(Box)`
  margin: 0;
  padding: 0;
`

const SocialLinks = styled(Box)`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  column-gap: 20px;
`

const ContactWrapper = styled(Box)`
  margin: 0;
  padding: 0;
`

const FooterGridContainer = styled(Grid)`
  display: flex;
  gap: 2rem;
  justify-content: center;
  max-width: ${MAX_CONTAINER_WIDTH};
  margin: 0 auto;
  padding: 1.8rem 0;
  color: var(--text-color);
`

const BottomFooterGridContainer = styled(FooterGridContainer)`
  padding: 1rem 0;
  ${onMobile} {
    gap: 0;
  }
`

const MainFooter = styled(Box)`
  background: ${COLORS.NEON};
`

const BottomFooter = styled(Box)`
  background: ${COLORS.SEA};
`

const FooterContainer = styled("footer")`
  margin-top: auto;
`

const Title = styled(Typography)`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 0.8rem;
`

const SubTitle = styled(Title)`
  font-weight: normal;
`

const IOXIOLogo = styled(IOXIOSvg)`
  align-self: center;
  width: 100%;
  overflow: visible;

  ${onMobile} {
    display: none;
  }
`
const BottomFooterGridItem = styled(Grid)`
  color: ${COLORS.WHITE};
  font-size: 0.8rem;
`
