import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
/*
Josh's Custom CSS Reset
https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
box-sizing: border-box;
}
* {
margin: 0;
}
html,
body {
height: 100%;
}
body {
line-height: 1.5;
-webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
display: block;
max-width: 100%;
}
input,
button,
textarea,
select {
font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
overflow-wrap: break-word;
}
#root,
#__next {
isolation: isolate;
}

/* End */

*,
*::before,
*::after {
box-sizing: border-box;
}

html,
body,
main {
font-family: Poppins, Verdana, Arial, sans-serif;
}

body {
font-size: 1rem;
line-height: 1.6;
margin: 0;
padding: 0;
}

strong {
font-weight: 600;
}

body,
html {
width: 100%;
height: 100%;
}

#__next {
height: 100%;
display: flex;
}

img {
max-width: 100%;
height: auto;
}

h1 {
margin-bottom: 0.8rem;
}
`
