import Typography from "@mui/material/Typography"
import { PrismicRichText } from "@prismicio/react"

import Heading from "./Heading"

export default function MUIRichText({ field }) {
  return (
    <PrismicRichText
      field={field}
      components={{
        heading1: ({ children, ...props }) => {
          return (
            <Heading size={1} {...props}>
              {children}
            </Heading>
          )
        },
        heading2: ({ children, ...props }) => {
          return (
            <Heading size={2} {...props}>
              {children}
            </Heading>
          )
        },
        heading3: ({ children, ...props }) => {
          return (
            <Heading size={3} {...props}>
              {children}
            </Heading>
          )
        },
        heading4: ({ children, ...props }) => {
          return (
            <Heading size={4} {...props}>
              {children}
            </Heading>
          )
        },
        paragraph: ({ children, ...props }) => {
          return <Typography {...props}>{children}</Typography>
        },
      }}
    />
  )
}
