import styled from "@emotion/styled"
import { Button as MUIButton } from "@mui/material"

const BaseButton = styled(MUIButton)`
  --button-text-color: white;
  --button-background-color: black;
  padding: 10px 28px;
  color: var(--button-text-color);

  text-transform: none;
  background-color: var(--button-background-color);
  border-radius: 22px;

  &:hover,
  &:active,
  &:focus {
    color: var(--button-background-color);
    background-color: var(--button-text-color);
  }
`

export default BaseButton
