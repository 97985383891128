const COLORS = {
  DIGIT: "#85FBC2",
  GRANITE: "#2F3037",
  ICE: "#CBD5E2",
  NEON: "#9A75E9",
  NIGHT_SKY: "#101920",
  SAND: "#EEEFEC",
  SEA: "#0F2543",
  SEA_2: "#426088",
  SEA_3: "#74A1DE",
  WHITE: "#FFFFFF",
}

export default COLORS
