import { createTheme, ThemeProvider as MUIThemeProvider } from "@mui/material"

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Verdana, Arial ,sans-serif",
    h1: {
      fontSize: "3.2rem",
      fontWeight: 700,
      lineHeight: "125%",
      padding: "1.2rem 0",
    },
    h2: {
      fontSize: "1.7rem",
      fontWeight: 700,
      lineHeight: "115%",
      letterSpacing: "0.01em",
      padding: "1.2rem 0",
    },
    h3: {
      fontSize: "1.4rem",
      fontWeight: 700,
      lineHeight: "125%",
      letterSpacing: "0.02em",
      padding: "1rem 0",
    },
    h4: {
      fontSize: "1.2rem",
      fontWeight: 700,
      lineHeight: "120%",
      letterSpacing: "0.02em",
      padding: "0.5rem 0",
    },
    body1: {
      margin: "0.5rem 0",
      lineHeight: "1.5rem",
    },
  },
})

export function ThemeProvider({ children }) {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
}
