function isActivationKey(event) {
  // Space or Enter
  return event.key === "Enter" || event.key === "Space"
}

export function interactionOnClickHandlers(onClick) {
  return {
    onClick: onClick,
    onKeyUp: function _onKeyUp(evt) {
      if (isActivationKey(evt)) {
        onClick()
      }
    },
  }
}

export function interactionOnChangeHandlers(onChange) {
  return {
    onChange: onChange,
  }
}

export function makeId(label) {
  return (
    label.toLowerCase().replace(/[^0-9a-z]/g, "-") +
    "-" +
    Math.random().toString(36).substr(2)
  )
}

export function splitIntoTwoColumns(arr) {
  const array = [...arr]
  const middleIndex = Math.ceil(array.length / 2)
  return [array.splice(0, middleIndex), array.splice(-middleIndex)]
}

function textToAnchorMemoized() {
  const cache = {}
  return function (text) {
    if (!(text in cache)) {
      const withoutSpaces = text.replace(/ /g, "-")
      cache[text] = withoutSpaces.replace(/(?!-)\W/g, "").toLowerCase()
    }
    return cache[text]
  }
}

export function getPathname(path) {
  let url = "/"
  try {
    url = new URL(path).pathname
  } catch (e) {
    console.log(e)
  }

  return url
}

export function getDevGuideUrl(doc) {
  return `/guides/${doc.uid}`
}

export const textToAnchor = textToAnchorMemoized()

export function formatFaqs(faqCategories, faqItems) {
  const faqs = []

  faqCategories.forEach((category) => {
    let obj = {
      items: [],
    }
    obj.name = category.data.title
    obj.order = category.data.order
    faqItems.forEach((item) => {
      if (item.data.category.id === category.id) {
        obj.items.push({
          question: item.data.question[0].text,
          answer: item.data.answer[0].text,
          order: item.data.order,
        })
      }
    })
    faqs.push(obj)
  })

  return faqs
}
